@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    body {
        @apply bg-primary;
    }

    .root {
        @apply h-screen;
    }
}